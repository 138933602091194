



































import ModalConvertUserAFP from "@/components/modal/user/ModalConvertUserAFP.vue";
import { UserActionMenu, UserPackageType } from "@/enums/enums";
import AuthService from "@/service/authService";
import { Component, Prop, Vue } from "vue-property-decorator";

const auth = new AuthService();
// const StoreUser = namespace("User");

@Component({
  components: { ModalConvertUserAFP },
})
export default class UserActionList extends Vue {
  @Prop({ required: true }) readonly item!: any;

  private userDropdownModelSelect: any = null;
  private remark = "";
  private asana = "";

  private userAction: any = [];
  private showList = false;
  private isShowConvertUserAFPModal = false;
  private menuPermission = false;

  constructor() {
    super();
    this.initializeMenuList();
  }

  public async initializeMenuList(): Promise<void> {
    const userProfilePermissions = await this.getUserProfilePermissions();
    this.menuPermission = userProfilePermissions.convertUserPackageType;
    this.userAction = [
      {
        text: "Convert to AFP",
        icon: "mdi-autorenew",
        actionValue: UserActionMenu.ConvertToAFP,
        permission: userProfilePermissions.convertUserPackageType,
      },
      {
        text: "Convert to Normal User",
        icon: "mdi-autorenew",
        actionValue: UserActionMenu.ConvertToNormal,
        permission: userProfilePermissions.convertUserPackageType,
      },
    ];
  }

  handleItemClick(actionItem: any) {
    const { actionValue } = actionItem;
    switch (actionValue) {
      case UserActionMenu.ConvertToAFP:
        this.ConvertUserAFP();
        break;
      case UserActionMenu.ConvertToNormal:
        this.ConvertUserAFP();
        break;
    }
  }

  public async getUserProfilePermissions() {
    const accessPermission = await auth.getPermissions();
    const permission = accessPermission["userProfile"];
    return permission;
  }

  handleShowItem(actionItem: any) {
    const { actionValue } = actionItem;
    const { userPackageType } = this.item;

    switch (actionValue) {
      case UserActionMenu.ConvertToAFP:
        return this.HideActionConvertToAFP(userPackageType);
      case UserActionMenu.ConvertToNormal:
        return this.HideActionConvertToNormal(userPackageType);
      default:
        return true;
    }
  }
  public HideActionConvertToAFP(userPackageType: number) {
    if (userPackageType === UserPackageType.AFP) {
      return false;
    }
    return true;
  }
  public HideActionConvertToNormal(userPackageType: number) {
    if (userPackageType === UserPackageType.Normal) {
      return false;
    }
    return true;
  }

  public ShowActionList(newValue: boolean) {
    this.showList = newValue;
  }

  public ConvertUserAFP() {
    this.isShowConvertUserAFPModal = true;
  }
}
