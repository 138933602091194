











































































































































































import { LoadingStatusType, TableSorting } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { UserAFPTable, UserAFPTableDetail, UserAFPTableRequest } from "@/model/user/userDetailModel";
import UserActionList from "@/views/user/UserActionList.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreUser = namespace("User");

@Component({
  components: { UserActionList },
})
export default class UserTable extends Vue {
  private table: any = {
    header: [
      { text: "", value: "index" },
      { text: "User ID", value: "UserID" },
      { text: "Username", value: "Username" },
      { text: "First name", value: "Firstname" },
      { text: "Last name", value: "Lastname" },
      { text: "Register DateTime", value: "RegisterDateTime" },
      // { text: "LastLogin DateTime", value: "LastLoginDateTime" },
      { text: "AFP Convert DateTime", value: "AFPConvertDateTime" },
      { text: "AFP Code", value: "AFPCode" },
      { text: "Affiliate", value: "affiliateCampaignName" },
      { text: "Action", value: "Action" },
    ],
    detail: [],
  };
  private action: any = [{ actionValue: 1, text: "แก้ไข" }];
  private pageNo = 1;
  private pageSize = 10;
  private actionValue = 0;
  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];
  private menufrom = false;
  private menuto = false;
  private datefrom = "";
  private dateto = "";
  private textSearchUserID = "";
  private textSearchUsername = "";
  private textSearchAFPCode = "";

  @StoreUser.Getter
  private getCountItemTable!: number;

  @StoreUser.Action
  private DoGetUserTable!: (userTable: UserAFPTableRequest) => void;
  @StoreUser.Getter
  private getUserTableLoadingStatus!: LoadingStatusType;
  @StoreUser.Getter
  private getUserDetailListModel!: UserAFPTable | null;
  @StoreUser.Getter
  private getConvertUserPackageTypeLoadingStatus!: LoadingStatusType;

  @Watch("pageNo")
  pageNoChanged(newVal: number) {
    this.GetUserTable(this.pageNo, this.pageSize);
  }

  @Watch("pageSize")
  pageSizeChanged(newVal: number) {
    this.GetUserTable(1, this.pageSize);
  }
  @Watch("getConvertUserPackageTypeLoadingStatus")
  getConvertUserPackageTypeLoadingStatusChanged(newVal: number) {
    if (newVal == LoadingStatusType.Success) {
      this.GetUserTable(this.pageNo, this.pageSize);
    }
  }

  @Watch("getUserTableLoadingStatus")
  getUserTableLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.table.detail = [];
      if (this.getUserDetailListModel && this.getUserDetailListModel.peakUserTableDetails.length > 0) {
        this.getUserDetailListModel.peakUserTableDetails.forEach((item: UserAFPTableDetail) => {
          this.table.detail.push(NewObject(item));
        });
      }
      this.countItem = this.getCountItemTable;

      if (this.countItem === 0) {
        this.totalPage = 1;
      } else {
        this.totalPage = Math.ceil(this.getCountItemTable / this.pageSize);
      }
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }

  created() {
    this.GetUserTable(this.pageNo, this.pageSize);
  }

  public GetUserTable(pageNo = this.pageNo, pageSize = this.pageSize) {
    const userRequest: UserAFPTableRequest = {
      pageNo: pageNo,
      pageSize: pageSize,
      tableSorting: TableSorting.DESC,
      userId: Number(this.textSearchUserID),
      userName: this.textSearchUsername,
      startConvertAfpDate: this.datefrom,
      toConvertAfpDate: this.dateto,
      afpCode: this.textSearchAFPCode,
    };
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.DoGetUserTable(userRequest);
  }

  public SearchUser() {
    this.pageNo = 1;
    this.pageSize = 10;
    this.GetUserTable(this.pageNo, this.pageSize);
  }

  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }

  validateInput() {
    this.textSearchUserID = this.textSearchUserID.replace(/\D/g, "");
  }
}
