






import { Component, Vue } from "vue-property-decorator";
import UserTable from "./UserTable.vue";

@Component({ components: { UserTable } })
export default class User extends Vue {}
